import { Dialog, List, ListItem } from "@mui/material";
import { useMap } from "react-map-gl";
import { useDispatch, useSelector } from "react-redux";

import {
  filteredLocations,
  setSelectedLocation,
  toggleShowList,
} from "../../state/reducers/locationsReducer";
import LocationItem from "../LocationCard/LocationCard";

import "./LocationList.scss";

const ListContainer = () => {
  const dispatch = useDispatch();
  const { current: map } = useMap();

  const visible = useSelector((state) => state.locations.showList);
  const locations = useSelector((state) => filteredLocations(state));

  const toggleList = () => dispatch(toggleShowList());

  const handleSelect = (location) => {
    dispatch(setSelectedLocation(location));
    dispatch(toggleShowList());

    map.flyTo({
      center: [location.longitude, location.latitude],
      zoom: 15,
    });
  };

  return (
    <Dialog
      open={visible}
      onClose={toggleList}
      onOpen={toggleList}
      anchor="right"
    >
      <header className="list-header">
        <div style={{ padding: "16px" }}>
          <h1 style={{ color: "black" }}>Locations</h1>
        </div>
        <hr />
      </header>

      <List>
        {locations?.map((location) => (
          <ListItem key={location.id} divider={true}>
            <LocationItem
              key={location.id}
              location={location}
              onClick={() => handleSelect(location)}
            />
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
};

export default ListContainer;
