import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
  theme: "light",
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setColorScheme(state, action) {
      state.theme = action.payload;
    },
  },
});

export const { setColorScheme } = appSlice.actions;

export default appSlice.reducer;
