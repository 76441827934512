import { IconButton, Typography } from "@material-tailwind/react";
import { Card, CardMedia, Divider, Fade } from "@mui/material";
import { CloseCircle } from "iconsax-react";
import { useDispatch, useSelector } from "react-redux";

import { setSelectedLocation } from "../../state/reducers/locationsReducer";
import { isMobile } from "../../utils/deviceUtils";

const GradientBackground = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="400"
    height="192"
    viewBox="0 0 400 192"
    fill="none"
  >
    <path
      d="M0 0H400V180C400 180 316.5 154 200 180C83.5 206 0 180 0 180V0Z"
      fill="url(#paint0_linear_17_1533)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_17_1533"
        x1="9.50001"
        y1="8.99999"
        x2="208"
        y2="156"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2494FC" />
        <stop offset="1" stopColor="#9D7AFF" />
      </linearGradient>
    </defs>
  </svg>
);

const MapItemContainer = () => {
  const dispatch = useDispatch();

  const location = useSelector((state) => state.locations.selectedLocation);

  if (!location) return null;

  const {
    name,
    water_fountain,
    bottle_filler,
    jug_filler,
    dog_fountain,
    public_access_days,
    opentime,
    closingtime,
  } = location;

  const clearSelectedLocation = () => dispatch(setSelectedLocation(null));

  return (
    <Fade in={!!location} timeout={1000}>
      <Card
        sx={{
          background: "white",
          borderRadius: "32px",
          boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
          margin: "5%",
          maxWidth: isMobile ? "90%" : "400px",
          overflow: "hidden",
          position: "absolute",
          right: "0",
          top: isMobile ? "unset" : "0",
          bottom: isMobile ? "0" : "unset",
          width: "fit-content",
          zIndex: 10000,
        }}
      >
        <CardMedia sx={{ position: "relative", height: "192px" }}>
          <IconButton
            className="absolute left-3 top-3"
            color="white"
            onClick={clearSelectedLocation}
            variant="text"
          >
            <CloseCircle size="32px" />
          </IconButton>
          <GradientBackground />
        </CardMedia>
        <div className="relative flex items-center justify-center p-4">
          <Typography variant="h5" className="text-center" color="blue-gray">
            {name || "Location Name"}
          </Typography>
        </div>
        <div className="text-center p-4">
          <Typography variant="h6">{`Water Fountain: ${
            water_fountain ? "✅" : `❌`
          }`}</Typography>
          <Typography variant="h6">{`Bottle Filler: ${
            bottle_filler ? "✅" : `❌`
          }`}</Typography>
          <Typography variant="h6">{`Jug Filler: ${
            jug_filler ? "✅" : `❌`
          }`}</Typography>
          <Typography variant="h6">{`Dog Fountain: ${
            dog_fountain ? "✅" : `❌`
          }`}</Typography>
        </div>
        <Divider />
        <div className="flex justify-center">
          <Typography
            variant="h5"
            className="m-4"
          >{`Opens: ${public_access_days} ${opentime || "N/A"}`}</Typography>
          <Typography variant="h5" className="m-4">{`Closes: ${
            closingtime || "N/A"
          }`}</Typography>
        </div>
      </Card>
    </Fade>
  );
};

export default MapItemContainer;
