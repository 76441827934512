import { Button, Grow } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { FILTERS } from "../../constants";
import { setFilter } from "../../state/reducers/locationsReducer";
import { isMobile } from "../../utils/deviceUtils";

const CircleSvg = ({ outlined, style }) => {
  return (
    <div style={style}>
      <svg width="50" height="50">
        <circle
          cx="25"
          cy="25"
          r="25"
          stroke="white"
          fill={outlined ? "transparent" : "white"}
          opacity="0.5"
        />
      </svg>
    </div>
  );
};

const Filters = () => {
  const dispatch = useDispatch();

  const activeFilter = useSelector((state) => state.locations.filter);
  const locations = useSelector((state) => state.locations.locations);

  const handleFilterSelect = (filter) => dispatch(setFilter(filter));

  return (
    <div
      style={{
        display: "flex",
        flexDirection: isMobile ? "row" : "column",
        justifyContent: "center",
        width: "100%",
        flexWrap: "wrap",
      }}
    >
      {FILTERS.map(({ type, color, name }, idx) => (
        <Grow in={true} timeout={1000} key={idx}>
          <Button
            key={idx}
            style={{
              margin: "0 16px 0",
              borderRadius: "16px",
              position: "relative",
            }}
            onClick={() => handleFilterSelect(type)}
          >
            <div
              style={{
                alignItems: "center",
                backgroundColor: color,
                borderColor: activeFilter === type ? "white" : "transparent",
                borderWidth: activeFilter === type ? "5px" : "0px",
                borderRadius: "16px",
                borderStyle: "solid",
                borderWidth: "2px",
                display: "flex",
                height: isMobile ? "80px" : "120px",
                overflow: "hidden",
                padding: "16px",
                width: isMobile ? "160px" : "220px",
                scale: activeFilter === type ? "1.2" : "1",
                transition: "scale 0.25s",
              }}
            >
              <div
                style={{
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: isMobile ? "row" : "column",
                  fontWeight: "bold",
                  textAlign: "left",
                }}
              >
                <div style={{ fontSize: "18px" }}>{name}</div>
                <div style={{ fontSize: isMobile ? "24px" : "32px" }}>
                  {locations?.filter((loc) => Number(loc[type]) === 1).length}
                </div>
                <CircleSvg
                  outlined
                  style={{ position: "absolute", top: "10px", right: "5px" }}
                />
              </div>
            </div>
          </Button>
        </Grow>
      ))}
    </div>
  );
};

export default Filters;
