import { Tooltip } from "@mui/material";
import CoffeeMakerIcon from "@mui/icons-material/CoffeeMaker";
import LocalDrinkIcon from "@mui/icons-material/LocalDrink";
import PetsIcon from "@mui/icons-material/Pets";
import WaterDropIcon from "@mui/icons-material/WaterDrop";

import "./LocationCard.scss";

/**
 * LocationItem
 *
 * @param {object} location - Location object
 * @param {string} location.name - Name of the location
 * @param {string} location.id - ID of the location
 * @param {string} location.water_fountain - Type of the location
 * @param {string} location.bottle_filler - Location has a bottle filler
 * @param {string} location.dog_fountain - Location has a dog fountain
 * @param {string} location.access - Location is accessible
 * @returns {JSX.Element} - LocationItem component
 */

const LocationItem = ({ location, onClick }) => {
  const { name, water_fountain, bottle_filler, dog_fountain, jug_filler } =
    location;

  return (
    <div className="list-item-container" onClick={() => onClick(location)}>
      <div className="list-item-title">
        <h3>{name}</h3>
      </div>
      <div className="list-item-info">
        {water_fountain ? (
          <Tooltip title="Water fountain available">
            <LocalDrinkIcon />
          </Tooltip>
        ) : (
          <div />
        )}
        {bottle_filler ? (
          <Tooltip title="Bottle filler available">
            <CoffeeMakerIcon />
          </Tooltip>
        ) : (
          <div />
        )}
        {dog_fountain ? (
          <Tooltip title="Dog fountain available">
            <PetsIcon />
          </Tooltip>
        ) : (
          <div />
        )}
        {jug_filler ? (
          <Tooltip title="Jug filler available">
            <WaterDropIcon />
          </Tooltip>
        ) : (
          <div />
        )}
      </div>
    </div>
  );
};

export default LocationItem;
