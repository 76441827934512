import { Link } from "@mui/material";
import PotableLogo from "../../assets/images/potable-logo.png";

const Logo = () => (
  <Link
    href="/"
    underline="none"
    style={{
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      padding: "1rem",
    }}
  >
    <img src={PotableLogo} alt="Potable Logo" />
    <h1
      style={{
        color: "black",
        fontSize: "25px",
        fontFamily: "Roboto",
        fontWeight: "bold",
        marginLeft: "0.5rem",
      }}
    >
      Potable
    </h1>
  </Link>
);

export default Logo;
