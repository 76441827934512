import { ThemeProvider } from "@mui/material/styles";
import { Provider } from "react-redux";

import store from "../state/store";
import theme from "../theme";

const Providers = ({ children }) => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </Provider>
  );
};

export default Providers;
