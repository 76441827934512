import { IconButton, Tooltip } from "@mui/material";
import { User } from "iconsax-react";

import supabase from "../../services/supabaseService";

const GoogleLogin = () => {
  const onClick = () => {
    supabase.auth.signInWithOAuth({
      provider: "google",
    });
  };

  return (
    <Tooltip title="Login with Google" followCursor arrow>
      <IconButton onClick={onClick} color="inherit">
        <User size="32px" color="grey" />
      </IconButton>
    </Tooltip>
  );
};

export default GoogleLogin;
