import { Fab } from "@mui/material";
import { useEffect } from "react";
import { useMap } from "react-map-gl";
import { useDispatch, useSelector } from "react-redux";

import { INITIAL_LOCATION } from "../../constants";
import { fetchLocationsInRadius } from "../../services/locationService";
import {
  filteredLocations,
  setSelectedLocation,
} from "../../state/reducers/locationsReducer";
import ListContainer from "../LocationList/ListContainer";
import MapItemContainer from "../MapItemDrawer/MapItemContainer";
import Marker from "../Marker/Marker";
import Navigator from "../Navigator/Navigator";
import BottomSheet from "../BottomSheet/BottomSheet";
import { isMobile } from "../../utils/deviceUtils";

const MapContent = () => {
  const dispatch = useDispatch();
  const { current: map } = useMap();

  const locations = useSelector((state) => filteredLocations(state));
  const searchLocation = useSelector((state) => state.locations.searchLocation);
  const selectedLocation = useSelector(
    (state) => state.locations.selectedLocation,
  );

  const goToMarker = ({ location: { latitude, longitude }, zoom = 15 }) => {
    if (!latitude || !longitude) return;

    dispatch(fetchLocationsInRadius({ latitude, longitude, zoom: 5 }));

    map?.flyTo({
      center: [longitude, latitude],
      zoom,
    });
  };

  const handleMarkerSelect = (location) => {
    dispatch(setSelectedLocation(location));

    map?.flyTo({
      center: [location.longitude, location.latitude],
      zoom: 15,
    });
  };

  useEffect(() => {
    const { latitude, longitude, zoom } = INITIAL_LOCATION;

    dispatch(fetchLocationsInRadius({ latitude, longitude, zoom }));
  }, []);

  useEffect(() => {
    if (!searchLocation) return;

    const latitude = _.get(searchLocation, ["coordinates", "latitude"], null);
    const longitude = _.get(searchLocation, ["coordinates", "longitude"], null);

    map?.jumpTo({ center: [longitude, latitude], zoom: 15 });
  }, [searchLocation, map]);

  const handleSearchArea = () => {
    const { lat: latitude, lng: longitude } = map?.getCenter();

    dispatch(
      fetchLocationsInRadius({
        latitude,
        longitude,
        zoom: map.getZoom(),
      }),
    );
  };

  return (
    <>
      <Fab
        onClick={handleSearchArea}
        variant="extended"
        sx={{
          margin: 2,
          right: isMobile ? "50%" : "2%",
          position: "absolute",
          justifyContent: "center",
          width: "fit-content",
          fontSize: isMobile ? "14px" : "1.25rem",
          fontWeight: "bold",
          border: "2px solid white",
        }}
      >
        <div>🎯 Search area</div>
      </Fab>
      {isMobile ? (
        <BottomSheet goToMarker={goToMarker} />
      ) : (
        <Navigator goToMarker={goToMarker} />
      )}
      {locations.map((location, idx) => (
        <Marker
          key={idx}
          location={location}
          onClick={handleMarkerSelect}
          selectedLocationId={selectedLocation?.id}
        />
      ))}
      <ListContainer />
      <MapItemContainer />
      {isMobile && (
        <div
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            borderRadius: "20px",
            bottom: "16px",
            height: "4px",
            left: "calc(50% - 20px)",
            position: "absolute",
            width: "40px",
          }}
        />
      )}
    </>
  );
};

export default MapContent;
