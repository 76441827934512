import _ from "lodash";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

import supabase from "../services/supabaseService";
import { setColorScheme } from "../state/reducers/appReducer";
import { loginSuccess, logout } from "../state/reducers/authReducer";

const Layout = ({ children }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    window
      .matchMedia("(prefers-color-scheme:dark)")
      .addEventListener("change", ({ matches }) => {
        dispatch(setColorScheme(matches ? "dark" : "light"));
      });

    return () => {
      window
        .matchMedia("(prefers-color-scheme:dark)")
        .removeEventListener("change", ({ matches }) => {
          dispatch(setColorScheme(matches ? "dark" : "light"));
        });
    };
  }, []);

  useEffect(() => {
    const initAuth = async () => {
      const { data } = await supabase.auth.getSession();

      const user = _.get(data, "session.user", null);

      if (user) {
        dispatch(loginSuccess(user));
      }
    };

    initAuth();
  }, []);

  useEffect(() => {
    supabase.auth.onAuthStateChange(async (event) => {
      if (event === "SIGNED_IN") {
        const { data } = await supabase.auth.getSession();

        dispatch(loginSuccess(data.session.user));
      } else if (event === "SIGNED_OUT") {
        dispatch(logout());
      }
    });
  });

  return <main style={{ overflow: "hidden" }}>{children}</main>;
};

export default Layout;
