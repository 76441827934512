import { useTheme } from "@mui/material";
import { useRef } from "react";
import { Marker as MapGlMarker } from "react-map-gl";

const Marker = ({ location, onClick, selectedLocationId }) => {
  const markerRef = useRef(null);
  const theme = useTheme();

  const isSelected = selectedLocationId === location.id;

  return (
    <MapGlMarker
      key={location.id}
      latitude={location.latitude}
      longitude={location.longitude}
      anchor="bottom"
      ref={markerRef}
      onClick={() => onClick(location)}
    >
      <div
        style={{
          width: isSelected ? "30px" : "15px",
          height: isSelected ? "30px" : "15px",
          borderRadius: "50%",
          opacity: 0.8,
          backgroundColor: isSelected
            ? theme.palette.secondary.main
            : theme.palette.primary.main,
          border: `5px solid ${theme.palette.primary.main}`,
        }}
      />
    </MapGlMarker>
  );
};

export default Marker;
