import { Box, styled, SwipeableDrawer } from "@mui/material";
import { drawerBleeding } from "../../constants";
import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import NavLinks from "../NavLinks/NavLinks";
import NavToolBar from "../NavToolBar/NavToolBar";

const Puller = styled("div")(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: "grey",
  borderRadius: 3,
  position: "absolute",
  top: 8,
  left: "calc(50% - 15px)",
}));

const BottomSheet = ({ goToMarker, openSearch, openSettings, loggedIn }) => {
  const drawerRef = useRef();
  const [open, setOpen] = useState(true);

  const locations = useSelector((state) => state.locations.locations);

  return (
    <SwipeableDrawer
      anchor="bottom"
      disableSwipeToOpen={false}
      ModalProps={{
        keepMounted: true,
      }}
      onClose={() => {
        setOpen(false);
      }}
      onOpen={() => {
        setOpen(true);
      }}
      open={open}
      ref={drawerRef}
      swipeAreaWidth={drawerBleeding}
      sx={{
        "& .MuiDrawer-paper": {
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16,
        },
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: -drawerBleeding,
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
          visibility: "visible",
          right: 0,
          left: 0,
        }}
      >
        <Puller />
        <Box
          sx={{
            px: 2,
            pb: 2,
            height: "100%",
            overflow: "auto",
          }}
        >
          Total water sources: {locations.length} nearby
        </Box>
      </Box>
      <NavLinks goToMarker={goToMarker} />
      <div
        style={{
          textAlign: "center",
          fontSize: "16px",
          marginBottom: "16px",
        }}
      >
        Total water sources: {locations.length} nearby
      </div>
      <NavToolBar />
    </SwipeableDrawer>
  );
};

export default BottomSheet;
