import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Portal,
  Switch,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import supabase from "../services/supabaseService";
import { setColorScheme } from "../state/reducers/appReducer";
import { toggleSettings } from "../state/reducers/settingsReducer";

const Settings = () => {
  const dispatch = useDispatch();

  const open = useSelector((state) => state.settings.open);
  const user = useSelector((state) => state.auth.user);
  const scheme = useSelector((state) => state.app.theme);

  const onClose = () => {
    dispatch(toggleSettings());
  };

  const handleLogout = async () => {
    await supabase.auth.signOut();
  };

  const handleThemeToggle = () => {
    const colorScheme = scheme === "light" ? "dark" : "light";

    dispatch(setColorScheme(colorScheme));
    localStorage.setItem("colorScheme", colorScheme);
  };

  return (
    <Portal>
      <Dialog open={open} onClose={onClose}>
        <div>
          <DialogTitle>User Settings</DialogTitle>
          <DialogContent>
            {!!user && (
              <div style={{ marginBottom: "8px" }}>
                <p>
                  Logged in as:{" "}
                  <span style={{ fontWeight: "bold" }}>{user?.email}</span>
                </p>
              </div>
            )}
            <hr style={{ marginTop: "16px", marginBottom: "16px" }} />
            <p>Account Settings</p>
            <div>
              <p>Color Theme: {scheme} mode</p>
              <Switch onClick={handleThemeToggle} />
            </div>
          </DialogContent>
          <DialogActions style={{ padding: "16px" }} className="flex-row">
            {false && (
              <Button variant="contained" onClick={onSave} color="primary">
                Update
              </Button>
            )}
            <Button variant="outlined" onClick={handleLogout}>
              Logout
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </Portal>
  );
};

export default Settings;
