import { createTheme } from "@mui/material/styles";

// color constants
export const PRIMARY = "#2196F3";
export const SECONDARY = "#f50057";
export const WARNING = "#ff9800";
export const ERROR = "#f44336";
export const SUCCESS = "#4caf50";

export default createTheme({
  palette: {
    primary: {
      main: PRIMARY,
    },
    secondary: {
      main: SECONDARY,
    },
    warning: {
      main: WARNING,
    },
    error: {
      main: ERROR,
    },
    success: {
      main: SUCCESS,
    },
  },
  typography: {
    fontFamily: "Roboto",
  },
});
