import { useRef } from "react";
import { GeolocateControl, Map, NavigationControl } from "react-map-gl";
import { useSelector } from "react-redux";

import { INITIAL_LOCATION } from "../../constants";
import { isMobile } from "../../utils/deviceUtils";
import MapContent from "./MapContent";

const MapContainer = () => {
  const geoControllerRef = useRef(null);
  const colorScheme = useSelector(({ app }) => app.theme);

  const locateUser = () => {
    if (!geoControllerRef.current) return;

    geoControllerRef.current.trigger();
  };

  return (
    <Map
      initialViewState={INITIAL_LOCATION}
      mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
      mapStyle={`mapbox://styles/mapbox/${colorScheme}-v10`}
      onLoad={locateUser}
      reuseMaps
      style={{ flex: 1, height: "100vh", width: "100vw" }}
    >
      <MapContent />
      <GeolocateControl
        ref={geoControllerRef}
        position={isMobile ? "top-right" : "bottom-right"}
      />
      <NavigationControl position={isMobile ? "top-right" : "bottom-right"} />
    </Map>
  );
};

export default MapContainer;
