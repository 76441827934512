import { Avatar } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { toggleSettings } from "../../state/reducers/settingsReducer";

const UserAvatar = ({ style }) => {
  const dispatch = useDispatch();
  const userPicture = useSelector(
    (state) => state?.auth?.user?.user_metadata?.picture || "",
  );

  const openSettings = () => dispatch(toggleSettings());

  return (
    <Avatar
      style={style}
      onClick={openSettings}
      src={userPicture}
      alt="Avatar Image"
    />
  );
};

export default UserAvatar;
