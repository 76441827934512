import { CssBaseline } from "@mui/material";

import MapContainer from "./components/Map/MapContainer";
import Layout from "./containers/Layout";
import Providers from "./containers/Providers";
import Settings from "./containers/Settings";

import "./App.scss";

const App = () => (
  <Providers>
    <CssBaseline />
    <div className="App" />
    <Layout>
      <MapContainer />
      <Settings />
    </Layout>
  </Providers>
);

export default App;
