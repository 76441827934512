import { AddressAutofill } from "@mapbox/search-js-react";
import { Collapse, TextField } from "@mui/material";
import { SearchNormal1 } from "iconsax-react";
import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setSearchLocation } from "../../state/reducers/locationsReducer";
import { getFeatureFromObject } from "../../utils/mapBoxUtils";

import "./SearchBar.scss";

const SearchBar = () => {
  const dispatch = useDispatch();

  const [searchValue, setSearchValue] = useState("");

  const ref = useRef(null);

  const showSearch = useSelector((state) => state.locations.showSearch);

  const handleSearch = ({ target: { value } }) => {
    setSearchValue(value);
  };

  const handleRetrieve = (feature) => {
    const selectedFeature = feature.features[0];

    const { coordinates, properties } = getFeatureFromObject(selectedFeature);

    setSearchValue(properties.full_address);
    dispatch(setSearchLocation({ coordinates, properties }));
  };

  return (
    <Collapse in={showSearch} timeout={500}>
      <AddressAutofill
        accessToken={process.env.REACT_APP_MAPBOX_TOKEN}
        onRetrieve={handleRetrieve}
        popoverOptions={{
          placement: "right-start",
          flip: true,
          offset: 20,
        }}
      >
        <TextField
          ref={ref}
          variant="outlined"
          value={searchValue}
          onChange={handleSearch}
          InputProps={{
            sx: { margin: "0 16px", padding: "8px" },
            placeholder: "Search for a location",
            startAdornment: (
              <SearchNormal1 size="32px" style={{ marginRight: "8px" }} />
            ),
            autoComplete: "shipping address-line1",
          }}
        />
      </AddressAutofill>
    </Collapse>
  );
};

export default SearchBar;
