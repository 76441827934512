import { combineReducers } from "redux";
import appReducer from "./appReducer";
import authReducer from "./authReducer";
import locationsReducer from "./locationsReducer";
import settingsReducer from "./settingsReducer";
import userReducer from "./userReducer";
import mapReducer from "./mapReducer";

const rootReducer = combineReducers({
  app: appReducer,
  auth: authReducer,
  locations: locationsReducer,
  settings: settingsReducer,
  user: userReducer,
  map: mapReducer,
});

export default rootReducer;
