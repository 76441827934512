import { IconButton, Toolbar } from "@mui/material";
import { SearchNormal1, Setting2, TextalignJustifycenter } from "iconsax-react";
import { useDispatch, useSelector } from "react-redux";

import {
  toggleShowList,
  toggleShowSearch,
} from "../../state/reducers/locationsReducer";
import { toggleSettings } from "../../state/reducers/settingsReducer";
import GoogleLogin from "../GoogleLogin/GoogleLogin";
import UserAvatar from "../UserAvatar/UserAvatar";

const NavToolBar = () => {
  const dispatch = useDispatch();

  const loggedIn = useSelector((state) => state.auth.isAuthenticated);

  const openListView = () => dispatch(toggleShowList());
  const openSearch = () => dispatch(toggleShowSearch());
  const openSettings = () => dispatch(toggleSettings());

  return (
    <Toolbar style={{ justifyContent: "space-between" }}>
      <IconButton onClick={openSettings}>
        <Setting2 size="32px" />
      </IconButton>
      <IconButton onClick={openSearch}>
        <SearchNormal1 size="32px" />
      </IconButton>
      <IconButton onClick={openListView}>
        <TextalignJustifycenter size="32px" />
      </IconButton>
      {loggedIn ? <UserAvatar /> : <GoogleLogin />}
    </Toolbar>
  );
};

export default NavToolBar;
