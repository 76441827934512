import { Divider, Drawer } from "@mui/material";
import { useSelector } from "react-redux";

import Filters from "../Filters/Filters";
import Logo from "../Logo/Logo";
import NavLinks from "../NavLinks/NavLinks";
import NavToolBar from "../NavToolBar/NavToolBar";
import SearchBar from "../SearchBar/SearchBar";

import "./Navigator.scss";

const Navigator = ({ goToMarker }) => {
  const locations = useSelector((state) => state.locations.locations);

  return (
    <Drawer
      anchor="left"
      variant="persistent"
      open
      sx={{
        flexShrink: 0,
        width: "240px",
        "& .MuiDrawer-root": {
          position: "static",
        },
        "& .MuiDrawer-paper": {
          minWidth: "240px",
          width: "fit-content",
          boxSizing: "border-box",
        },
      }}
    >
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Logo />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <div>
          <NavLinks goToMarker={goToMarker} />
          <div className="pb-20">
            <Divider />
          </div>
          <div style={{ padding: "1rem" }}>
            <Filters />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              textAlign: "center",
              fontSize: "16px",
              marginBottom: "16px",
            }}
          >
            Total water sources: {locations.length} nearby
          </div>
          <div className="pb-10">
            <Divider />
          </div>
          <SearchBar />
          <NavToolBar />
        </div>
      </div>
    </Drawer>
  );
};

export default Navigator;
