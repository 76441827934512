import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  open: false,
};

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    toggleSettings(state) {
      state.open = !state.open;
    },
  },
});

export const { toggleSettings } = settingsSlice.actions;

export default settingsSlice.reducer;
