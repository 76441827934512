export const getFeatureFromObject = (featureObject) => {
  const { geometry, properties } = featureObject;
  const { coordinates } = geometry;

  const [longitude, latitude] = coordinates;

  return { coordinates: { longitude, latitude }, properties };
};

const checkZoomLevel = (zoomLevel) => {
  const minZoom = 7;
  const maxZoom = 16;

  if (!zoomLevel || zoomLevel >= maxZoom) {
    return maxZoom;
  } else if (zoomLevel <= minZoom) {
    return minZoom;
  }

  return zoomLevel;
};

//function can be moved later. Meant to be used to calculate zoom radius
//to render a certain amount of markers based on distance
export const calculateRadius = (currentZoomLevel) => {
  let radius;

  const baseZoom = 12.5;
  const baseRadius = 6438; //this is supposed to be in meters. so 6438M = 4mi .

  const zoomLevel = checkZoomLevel(currentZoomLevel);

  baseRadius * Math.pow(2, baseZoom - zoomLevel) < 3218
    ? (radius = 3218.69) //within a minimum of 2 miles.
    : (radius = baseRadius * Math.pow(2, baseZoom - zoomLevel));

  return radius;
};
