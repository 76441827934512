import { List, ListItemButton } from "@mui/material";
import { LA_LOCATION, NYC_LOCATION, SF_LOCATION } from "../../constants";

const NavLinks = ({ goToMarker }) => (
  <List
    style={{
      padding: "1rem",
      display: "flex",
      flexDirection: "column",
      gap: "16px",
    }}
  >
    <ListItemButton onClick={() => goToMarker({ ...SF_LOCATION })}>
      <div style={{ fontSize: "24px", display: "flex", flexDirection: "row" }}>
        <span style={{ marginRight: "16px" }}>🌁</span>SF
      </div>
    </ListItemButton>
    <ListItemButton onClick={() => goToMarker({ ...LA_LOCATION })}>
      <div style={{ fontSize: "24px", display: "flex", flexDirection: "row" }}>
        <span style={{ marginRight: "16px" }}>🌅</span>LA
      </div>
    </ListItemButton>
    <ListItemButton onClick={() => goToMarker({ ...NYC_LOCATION })}>
      <div style={{ fontSize: "24px", display: "flex", flexDirection: "row" }}>
        <span style={{ marginRight: "16px" }}>🌆</span>NYC
      </div>
    </ListItemButton>
  </List>
);

export default NavLinks;
