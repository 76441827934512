import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  lat: -122.445,
  lng: 37.765,
  zoom: 12.25,
};

const mapSlice = createSlice({
  name: "map",
  initialState,
  reducers: {
    setLatitude: (state, action) => {
      state.lat = action.payload;
    },
    setLongitude: (state, action) => {
      state.lng = action.payload;
    },
    setZoom: (state, action) => {
      state.zoom = action.payload;
    },
  },
});

export const { setLatitude, setLongitude, setZoom } = mapSlice.actions;

export default mapSlice.reducer;
